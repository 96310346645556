/*******************************
GLOBAL STYLE START
*******************************/
body {
	font-size: 18px;
	font-family: 'Roboto', sans-serif;
	line-height: 1.5;
    background-color: #fff;
}
a:hover { text-decoration: none; }
.btn { 
    padding: 14px 24px; 
    color: #9D3102;
    font-size: 18px;
    font-weight: 600; 
    line-height: 22px;
    border: none; 
    text-transform: uppercase;
}
.btn-primary {
    color: #fff;
    background-color: #EAAF00;
}
.btn-secondary {
    color: #9D3102;
    background-color: #fff;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{background-color: #9D3102; border-color: #9D3102;}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus{box-shadow: none;}
.btn-primary:hover,
.btn-primary:focus,
.btn-secondary:hover,
.btn-secondary:focus{
    color: #fff;
    background-color: #9D3102;
    box-shadow: none;
}

.bg-yellow { background-color: #EAAF00; }
h1,h2,h3 { font-weight: 700; }
h4,h5,h6 { font-weight: 600; }
h1 { font-size: 30px; line-height: 36px; color: #ffffff;}
h2 { font-size: 24px; line-height: 28px; }
h3 { font-size: 20px; line-height: 24px; }
h4 { font-size: 18px; }
h5 { font-size: 16px; }
h6 { font-size: 14px; }
p { 
    font-size: 14px;
    line-height: 1.8;
    font-weight: 400;
    margin-bottom: 1.5rem;
}

@media(min-width:768px){
    h1 { font-size: 50px; line-height: 60px; }
    h2 { font-size: 36px; line-height: 46px; }
    h3 { font-size: 34px; line-height: 40px; }
    p { font-size: 16px; }
}
/*******************************
GLOBAL STYLE END
*******************************/


/*******************************
HEADER STYLE START
*******************************/

/**** Header-Top Style Start ***/
.header-top {
    display: none;
} 

@media(min-width:768px){
    .header-top {
        display: flex;
        padding: 10px 0;
        background: #9D3102;
    } 

    .header-top .header-top-text p { color: #fff; font-size: 16px; margin: 0;}
    .header-top .header-top-text p,
    .header-top .call-info { font-size: 16px; color: #fff;}
    .header-top .call-info a {color: #fff;}
}
@media (min-width: 992px){
    .header-top .call-info {margin-top: 0px;}  
}
@media (min-width: 1300px){
    .header-top .container-fluid { padding: 0 50px; } 
}
/**** Header-Top Style End ***/

/**** NAVIGATION STYLE START ***/
.header-middle {width: 100%; padding: 10px 0; z-index: 9999;}
.header-middle a.logo img {width: 200px;}
.header-middle .nav-wrapper  {
    display: none;
    position: absolute;
    top: 65px;
    left: 0;
    z-index: 1000;
    width: 100%;
    max-height: 500px;
    overflow-y: auto;
}
.header-middle .nav-wrapper ul { 
    list-style: none;
    margin: 0;
    padding: 0;
}
.header-middle .nav-wrapper ul li{display: block; width: 100%;}
.header-middle .nav-wrapper ul li a {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 12px 15px;
    color: #fff;
    background-color: #9D3101;
    border-bottom: 1px solid #AB542E;
}
.header-middle .nav-wrapper ul li.donate a svg * { fill: #fff; }
.header-middle .nav-wrapper ul > li:hover > a,
.header-middle .nav-wrapper ul > li:focus > a { color: #fff; }
.header-middle .nav-wrapper ul li.donate a svg { margin-right: 10px; }
.header-middle .nav-wrapper ul li.donate:hover a { color: #fff; background: #9D3102; }
.header-middle .nav-wrapper ul li.has-submenu > a::after {
    content: "";
    position: relative;
    z-index: 2;
    border: 0 solid #fff;
    height: 10px;
    width: 10px;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    float: right;
    vertical-align: middle;
    margin: 7px 10px 0 0;
}
.header-middle .nav-wrapper ul ul {
    display: none;
    position: relative;
    left: 0;
    z-index: 1;
    min-width: 210px;
    margin-top: 0;
    background-color: #9D3101;
}

.header-middle .nav-wrapper ul li li { display: block; }
.header-middle .nav-wrapper ul li li,
.header-middle .nav-wrapper ul li li:not(:first-child) { padding-left: 0; }
.header-middle .nav-wrapper ul li li:not(:first-child) { border-top: solid 1px rgba(157, 49, 2, .2); }
.header-middle .nav-wrapper ul li li a {
    display: block;
    padding: 12px 30px;
    font-size: 16px;
    color: #fff;
    white-space: nowrap;
    background-color: #9D3101;
}
.header-middle .nav-wrapper ul li li:hover a,
.header-middle .nav-wrapper ul li li:focus a {
    background-color: #9D3102;
}
.header-middle .nav__toggle {
    position: absolute;
    right: 20px;
    z-index: 1;
    display: inline-block;
    width: 20px;
    padding: 0;
    background-color: transparent;
    border: 0;
}
.header-middle .nav__toggle span {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #9D3102;
    -webkit-transition: .3s ease-in-out;
    -moz-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}
.header-middle .nav__toggle span:not(:last-child) { margin-bottom: 4px; }
.header-middle .nav__toggle.active span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 5px;
    left: 0;
    margin: 0;
}
.header-middle .nav__toggle.active span:nth-child(2) {
    width: 0%;
    opacity: 0;
}
.header-middle .nav__toggle.active span:nth-child(3) {
    margin: -8px 0 0;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: -11px;
    left: 0;
}
@media(min-width:360px){
    .header-middle .nav-wrapper { max-height: 550px; }
}
@media(min-width:992px){
    .header-middle { padding: 20px 0; }
    .header-middle .nav-wrapper { max-height: inherit; overflow-y: inherit; }
    .header-middle .nav-wrapper ul li.has-submenu a::after {
        content: "";
        float: none;
        display: inline-block;
        vertical-align: middle;
        margin: 0 0 0 7px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: #9d3002 transparent transparent transparent;
        transform: none;
    }
    .header-middle .nav-wrapper ul li.has-submenu:hover a::after,
    .header-middle .nav-wrapper ul li.active a::after { border-color: #fff transparent transparent transparent; }
    .header-middle .nav-wrapper ul li:hover ul { display: block; }
    .header-middle .nav-wrapper ul li.donate a svg * { fill: #9D3102; }
    .header-middle .nav-wrapper ul li.donate:hover a svg * { fill: #fff; }
    .header-middle .nav-wrapper ul li.donate { margin-left: 10px; }
    .header-middle .nav-wrapper ul li.donate a {
        padding: 12px 20px;
        font-size: 16px;
        text-transform: uppercase;
        background: #fff;
        border-radius: 6px;
    }
    .header-middle .nav-wrapper ul li { position: relative; display: inline-block; width: auto;}
    .header-middle .nav-wrapper  {
        display: inline-block;
        position: relative;
        top: inherit;
        left: 0;
        width:auto;
        background-color: transparent;
    }
    .header-middle .nav-wrapper ul li a {
        width: auto;
        padding: 0 10px;
        color: #fff;
        background-color: transparent;
        border-bottom: none;
        font-size: 16px;
        font-weight: 500;
        color: #9D3102;
        text-transform: capitalize;
    }
    .header-middle .nav__toggle { display: none; }
    .header-middle .nav-wrapper ul ul {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1;
        min-width: 210px;
        margin-top: 30px;
        background-color: #fff;
    }
    .header-middle .nav-wrapper ul ul:before {
        content: "";
        position: absolute;
        top: -37px;
        left: 0;
        width: 100%;
        height: 37px;
    }
    .header-middle .nav-wrapper ul li.active li a { color: #9D3102; }
    .header-middle .nav-wrapper ul li.active a { color: #fff; }
    .header-middle .nav-wrapper ul li li:hover a,
    .header-middle .nav-wrapper ul li li.active a { color: #fff; background-color: #9D3102; }
    .header-middle .nav-wrapper ul li li a { background-color: #fff; color: #9D3102; padding: 12px 30px; font-size: 18px; }
    .header-middle .nav-wrapper ul li.has-submenu:active >.nav-link { color: #ffffff; background: url("../img/select-dropdown-white.svg") no-repeat right center; }
    .header-middle .nav-wrapper ul li.has-submenu li > a::after { display: none; }
}

@media (min-width: 1200px){
    .header-middle .nav-wrapper {display: block;}
    .header-middle a.logo img {width: auto;}
    .header-middle .nav-wrapper ul li a { padding: 0 15px; font-size: 18px; }
    .header-middle .nav-wrapper ul ul {margin-top: 37px;}
    .header-middle .nav-wrapper ul li.donate a { font-size: 18px; }
    .header-middle .nav-wrapper ul li.donate a{font-size: 18px;}
}
@media(min-width:1300px){
    .header-middle .container-fluid { padding: 0 50px; }
}
/**** NAVIGATION STYLE END ***/

/*******************************
HEADER STYLE END
*******************************/


/*******************************
HOMEPAGE STYLE START
*******************************/

/**** Hero Banner STYLE START ****/
.hero_banner {
    height: 350px;
    background-position: right center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #9D3102;
}
.hero_banner::after{
    content: "";
    background: rgba(157,49,2, 0.7);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}
.hero_banner .banner-content {
    max-width: 670px;
    width: 100%;
    text-align: center;
    margin: 0;
    z-index: 100;
    position: relative;
}
.hero_banner .banner-content h1 {
    font-weight: normal;
    color: #ffffff; 
    margin-bottom: 30px;
}
.hero_banner .btn.btn-secondary { color: #9D3102; }
.hero_banner .btn.btn-secondary svg { margin-right: 5px; }
.hero_banner .btn.btn-secondary:hover { background-color: #EAAF00; color: #ffffff; }
.hero_banner .btn.btn-secondary:hover svg * { fill: #ffffff; }
.tell-friend { 
    text-align: center;
    padding: 10px 0;
}
.tell-friend a {
    font-size: 16px; 
    color: #9D3102; 
    font-weight: 500;
}
.tell-friend svg { margin-right: 5px; width: 50px; height: 25px; }

@media(min-width:992px){
    .tell-friend a { font-size: 26px; }
    .hero_banner { height: 500px; }
    .hero_banner::after{
        content: "";
        background: transparent;
    }
    .tell-friend svg { width: 66px; height: 33px; }
    .tell-friend {
        position:absolute;  
        right: 50px;
        width: auto;
        top: 590px;
    }
    .hero_banner .banner-content { text-align: left; }
}
@media(min-width:1200px){
    .hero_banner::after{
        content: "";
        background: linear-gradient(90deg, rgba(157,49,2,1) 15%, rgba(157,49,2,0) 50%);
    }
    .gradient-overlay { padding: 170px 0px; }
}
/**** HERO BANNER STYLE END ****/


/****ACTIVITY STYLE START ****/
.activity {
    padding-top: 30px; 
    padding-bottom: 0;
}
.activity .container {
    padding: 0;
    overflow-x: auto;
}
.activity .container .row {
    display: -webkit-inline-box;
    display: inline-flex;
    flex-wrap: inherit;
}
.activity .container .row .col-lg-4 {
    width: 230px;
    flex-basis: inherit;
    -webkit-box-flex: inherit;
    flex-grow: inherit;
    max-width: inherit;
}
.activity .activity_inside {
    padding: 20px;
    text-align: center;
    margin: auto;
    border: 1px solid #EAAF00;
    border-radius: 6px;
}
.activity .activity_inside svg { width: 80px; height: 80px; margin-bottom: 20px; }
.activity .activity_inside p { margin: 0; font-weight: 500; line-height: 1.4; }
.activity .activity_inside h2 sub {font-size: 30px; bottom: 0;}
.activity .activity_inside h2 { color:#9D3102; margin-bottom: 20px;}
.activity .activity_inside h2::before{
    content: "";
    border-top: 1px solid #B7B7B7;
    max-width: 50%;
    margin: 0 auto;
    display: block;
    padding-top: 20px;
}

@media(min-width:768px){
    .activity { padding-top: 70px; }
    .activity .activity_inside { padding: 40px 20px; }
    .activity .activity_inside p {font-size: 16px;} 
    .activity .activity_inside svg { width: 140px; height: 140px; }
    .activity .container { padding: 0 15px; overflow-x: inherit; }
    .activity .container .row { display: flex; flex-wrap: wrap; }
    .activity .container .row .col-lg-4 { width: 33.33%; max-width: 33.33%; }
}
/** ACTIVITY STYLE END ***/

/*** COW SERVICES STYLE START ***/
.best_cow_service{padding-top: 50px; padding-bottom: 50px;}
.best_cow_service .best_cow_img { margin: 0 auto;}
.best_cow_service .best_cow_img img { width: 100%;}
.best_cow_service .best_cow_details {
    padding: 20px; 
    background-color: #EAAF00; 
    border-radius: 6px; 
    position: relative;
}
.best_cow_service .best_cow_details p { color: #ffffff; }
.best_cow_service .best_cow_details p:last-child { margin-bottom: 0; }
.best_cow_service .best_cow_details h2 {color: #ffffff; margin-bottom: 20px;}
@media(min-width:768px){
    .best_cow_service { padding-top: 70px; padding-bottom: 70px; }
    .best_cow_service .best_cow_details { padding: 60px 80px; margin-top: 30px; }
    .best_cow_service .best_cow_img{
        padding: 10px;
        background-color: #ffffff;
        box-shadow: 0 10px 50px -2px rgb(0 0 0 / 14%);
        bottom: 0;
        position: relative;
        z-index: 10;}
    .best_cow_service .best_cow_img img { width:100%; }
    .best_cow_service .best_cow_details h2 { margin-bottom: 40px; }
}
@media (min-width:992px) {
    .best_cow_service .best_cow_img { width: 370px;margin-bottom: 0;}
    .best_cow_service .best_cow_details { margin: 0; } 
}
@media (min-width:1200px) {
    .best_cow_service .best_cow_img { width: 440px; right: 20px;}
}
/**** COW SERVICES Style End ****/

/**** Organization Style Start ****/
.organization{
    padding: 50px 0;
    min-height: 600px;
    position: relative;
    background-position: left bottom !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-color:#9D3102;
}
.organization .tab_container {margin-top: 30px; position: relative;}
.organization h2 {color:#9D3102;}
.organization .btn-readmore{background-color: #EAAF00; color: #ffffff; margin-top: 20px;}
.organization .btn-readmore:hover{background-color: #9D3102; color: #ffffff;}
.organization .tabs { display: none; }
.organization .tab_drawer_heading {
    background-color: #9D3102;
    border-top: 1px solid rgb(255,255,255, 30%);
    color: #ffffff;
    margin: 0;
    padding: 14px 20px;
    display: block;
    cursor: pointer;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.organization .tab_container .tab_drawer_heading::after{
    content: "";
    position: relative;
    z-index: 2;
    border: 0 solid #fff;
    height: 10px;
    width: 10px;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    float: right;
    vertical-align: middle;
    margin: 7px 10px 0 0;
}
.organization .tab_container .d_active::after {transform: rotate(-134deg);}
.organization .d_active {
    background-color: #9D3102;
    color: #fff;
}
.organization .tab_content {
    padding: 20px;
    background-color: #fff;
}
.organization .tab_container h3 { font-size: 18px; }

@media (min-width:768px){
.organization{
    padding: 84px 0; 
}
.organization ul.tabs {
	padding: 0;
	float: left;
	list-style: none;
	height: 50px;
	width: 100%;
    margin-top: 20px;
}
.organization .tabs { display: block; }
.organization ul.tabs li {
	float: left;
	margin: 0 30px 0 0;
	cursor: pointer;
	padding: 6px 0;
	line-height: 31px;
	border-bottom: 2px solid transparent;
	background-color: transparent;
	overflow: hidden;
	position: relative;
    font-size: 18px;
    font-weight: 600;
}
.organization ul.tabs li:hover { background-color: transparent; }
.organization ul.tabs li.active {
	background-color: transparent;
	border-bottom: 2px solid #EAAF00;
	display: block;
}
.organization .tab_container {
	border-top: none;
	clear: both;
	float: left;
	width: 100%;
	background: transparent;
	overflow: auto;
    margin-top:0px;

}
.organization .tab_content {padding:0; background-color: transparent;}
.organization .tab_drawer_heading { display: none;}
}
/**** Organization Style End ****/

/**** Quote Style Start ****/
.quote{padding: 50px 0;}
.quote .quote-info{
    padding: 40px; 
    border-top-left-radius:70px; 
    border-bottom-right-radius: 70px; 
    color: #ffffff; 
    text-align: center;
}
.quote .quote-info h2 { font-size: 18px; line-height: 26px; }
.quote .quote-info h3 { margin: 15px 0 0; font-size: 20px; }
@media(min-width:768px){
    .quote .quote-info h2 { font-size: 30px; line-height: 40px;}
    .quote .quote-info h3 { font-size: 30px; }
    .quote .quote-info { padding: 50px; }
    .quote { padding: 70px 0; }
}
@media(min-width:992px){
    .quote .quote-info h2{font-size: 34px; line-height: 46px;}
    .quote .quote-info h3{font-size: 34px;}
    .quote .quote-info{padding: 90px;}
}

/**** Quote Style End ****/

/*** Adopt Animal Style Start ****/
.adopt-animal { padding: 20px 0 40px; }
.adopt-animal-page { padding: 40px 0; }
.adopt-animal-page .col-lg-12 { padding: 0 5px; }
.adopt-animal h2 {
    color: #9D3102; 
    margin-bottom: 30px; 
    text-align: center;
}
.adopt-animal .row { margin: 0 -5px; }
.adopt-animal .row .col-4 { padding: 0 5px; }
.adopt-animal .adopt-animal-card {
    margin: 0 auto;
    padding: 15px 10px;
    text-align: center;
    border: 1px solid #EAAF00;
    border-radius: 6px;
}
.adopt-animal .adopt-animal-card .card-details { padding: 10px 0; margin-bottom: 30px;}
.adopt-animal .adopt-animal-card .card-title { margin-bottom: 10px; }
.adopt-animal .adopt-animal-card .card-title h3 { font-size: 12px; font-weight: 400; line-height: 18px; }
.adopt-animal .adopt-animal-card .card-details { padding: 0; margin-bottom: 10px; }
.adopt-animal .adopt-animal-card .card-details h4 { font-size: 13px; color: #9D3102; font-weight: 600; }
.adopt-animal .adopt-animal-card .btn { padding: 6px 10px; font-size: 10px; line-height: 16px; }
@media(min-width:768px){
    .adopt-animal h2 { margin-bottom: 50px; }
    .adopt-animal .row { margin: 0 -15px; }
    .adopt-animal .row .col-4,
    .adopt-animal-page .col-lg-12 { padding: 0 15px; }
    .adopt-animal-page { padding: 50px 0; }
    .adopt-animal .adopt-animal-card { padding: 46px 20px; max-width: 264px; }
    .adopt-animal .adopt-animal-card .card-title h3 { font-size: 24px; line-height: 30px; }
    .adopt-animal .adopt-animal-card .card-details { padding: 10px 0; margin-bottom: 30px; }
    .adopt-animal .adopt-animal-card .card-details h4 { font-size: 44px; }
    .adopt-animal { padding-bottom: 70px;}
    .adopt-animal-page { padding:70px 0; }
    .adopt-animal .adopt-animal-card .btn { padding: 14px 24px; font-size: 18px; line-height: 22px; }
}
@media(min-width:992px){
    .adopt-animal .adopt-animal-card .card-details h4 { font-size: 52px; }
}
/****Adopt Animal Style End****/

/****Donation CTA Style Start****/
.donation_cta {
    height: 350px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color:#9D3102;
}
.donation_cta::after{
    content: "";
    background: rgba(157,49,2, 0.8);
    width: 100%;
    height: inherit;
    position: absolute;
}
.donation_cta .donation_cta-info{
    position: relative;
    text-align: center; 
    margin: 0 auto; 
    color: #ffffff; 
    z-index: 1;
}
.donation_cta .donation_cta-info h2 { margin-bottom: 20px; }
.donation_cta .donation_cta-info p {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 30px;
}
.donation_cta svg{margin-right: 5px;}
.donation_cta .btn.btn-secondary:hover{
    background-color: #EAAF00;
    color: #ffffff;
}
.donation_cta .btn-cta:hover {background-color: #EAAF00;}
.donation_cta .btn.btn-secondary:hover svg *{fill: #ffffff;}
.alert.alert-warning p {margin: 0;}
@media(min-width:768px){
    .donation_cta .donation_cta-info h2 { margin-bottom: 30px; }
    .donation_cta .donation_cta-info p { max-width: 750px; }
}
/**** Donation CTA Style End ****/

/**** TESTIMONIAL SECTION STYLE START ****/
.testimonial-section { padding: 40px 0 50px; }
.testimonial-slider .testimonial-block { padding: 0 15px; }
.testimonial-wrapper {
    padding: 20px;
    border: solid 1px #EAAF00;
    border-radius: 8px;
}
.testimonial-wrapper svg { margin-bottom: 35px; }
.testimonial-wrapper h2 {
    font-size: 16px;
    font-weight: normal;
    line-height: 28px;
}
.testimonial-wrapper h2 {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: solid 1px rgba(112, 112, 112, .2);
}
.testimonial-wrapper .user-info .user-photo { margin-right: 14px; }
.testimonial-wrapper .user-info .user-photo img {
    width: 46px;
    height: 46px;
    border: solid 1px #EAAF00;
    border-radius: 50%;
}
.testimonial-wrapper .user-info .user-name h3 { 
    margin: 0;
    line-height: 24px;
    font-size: 18px; 
    font-weight: 700; 
}
.testimonial-wrapper .user-info .user-name p { margin: 0; font-size: 14px; }
.testimonial-slider .slick-prev {
    position: absolute;
    top: 45%;
    z-index: 2;
    left: -40px;
    margin: 0;
    width: 46px;
    height: 46px;
    background: #EAAF00 url("../img/arrow-right.svg") no-repeat center center;
    border-radius: 6px;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}
.testimonial-slider .slick-prev:before,
.testimonial-slider .slick-next:before { display: none; }
.testimonial-slider .slick-next {
    position: absolute;
    top: 45%;
    z-index: 2;
    right: -40px;
    margin: 0;
    width: 46px;
    height: 46px;
    background: #EAAF00 url("../img/arrow-right.svg") no-repeat center center;
    border-radius: 6px;
    transform: none;
    -webkit-transform: none;
}
.testimonial-slider .slick-prev:hover,
.testimonial-slider .slick-next:hover {
    background: #9D3102 url("../img/arrow-right.svg") no-repeat center center;
}
.testimonial-slider .slick-dots { bottom: -40px; }
.testimonial-slider .slick-dots li {
    width: auto;
    height: auto;
}
.testimonial-slider .slick-dots li button {
    padding: 0;
    width: 14px;
    height: 14px;
    border: solid 1px #EAAF00;
    border-radius: 50%;
}
.testimonial-slider .slick-dots li.slick-active button { background-color: #EAAF00; }
.testimonial-slider .slick-dots li button:before { display: none; }

@media (min-width:768px){
    .testimonial-section { padding: 70px 0; }
    .testimonial-slider { margin: 0 15px; }
    .testimonial-wrapper { padding: 35px; min-height: 400px; }
}
@media (min-width:1200px){
    .testimonial-slider { margin: 0 10px; }
    .testimonial-slider .slick-prev {left: -50px;}
    .testimonial-slider .slick-next {right: -50px;}
}
@media (min-width:1400px){
    .testimonial-slider { margin: 0 -15px; }
}   

/**** TESTIMONIAL SECTION STYLE END ****/

/*******************************
HOMEPAGE STYLE END
*******************************/


/*******************************
Footer Style Start
*******************************/
footer{
    position:relative;
    float: left;
    padding: 30px 0;
    background-color: #FDF7E5;
    overflow: hidden;
}
footer .footer-info { padding: 10px 0 0; }
footer .footer-info div {
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
}
footer .footer-info a {
    /* font-size: 28px; */
    color: #9D3102;
    /* font-weight: 600; */
    text-decoration: none;
    display: inline-block;
}
footer .footer-info .social-icons { margin-top: 18px; }
footer .footer-info .social-icons i{
    margin: 0 auto; 
    line-height: 0;
}
footer .footer-info .social-icons a {
    display: inline-flex;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    background-color: #EAAF00;
    border-radius: 6px;
    margin-left: 2px;
}
footer .footer-info .social-icons a:hover{background-color: #9D3102;}
footer .copyright{
    padding: 20px 0 0; 
    border-top: 1px solid #EAAF00; 
    margin: 20px 0 0; 
    text-align: center;
}
footer .copyright .links{text-align: center;}
footer .copyright ul.links{list-style: none;}
footer .copyright ul li{
    border-right: 1px solid #9D3102; 
    height: 18px; 
    display:inline-flex;
    align-items: center;
}
footer .copyright ul li:last-child{border-right: none;}
footer .copyright .links li:first-child a{padding-left: 0;}
footer .copyright .links li a:hover{color: #EAAF00;}
footer .copyright .links p{
    padding: 0 20px; 
    font-size: 16px; 
    font-weight: 500; 
    color: #9D3102;
}
footer .copyright .links a{
    padding: 0 20px; 
    font-size: 16px; 
    font-weight: 700; 
    color: #9D3102; 
    border-right: 1px solid #9D3102; 
    cursor: pointer;
}
footer .copyright small a { color: #000; text-decoration: underline; }
footer .copyright small a:hover { color: #9D3102; }
footer .copyright .links a:last-child { border-right: none;}
footer .copyright .copy-info p { font-size: 14px;}
.company-regd-info p { font-size: 16px; font-weight: 500; line-height: 20px; color:#9D3102;}
.footer-left-cow-image{display: none;}
.footer-right-cow-image{display: none;}
@media(min-width:768px){
    footer{
        position:relative; 
        width: 100%;
        right: 0; 
        bottom: 0;
        background: url("../img/footer_shape_bg.svg") no-repeat; 
        background-size: cover; 
        padding: 70px 0 0;
        background-position: center top;
    }
    footer .footer-info p { margin-bottom: 0; }
    footer .copyright { padding: 30px 0; margin: 30px 0 0; }
    footer .copyright .links { margin-bottom: 20px; }
}
@media(min-width:1300px){
    .footer .container { max-width: 1000px; }
    .footer-left-cow-image { position: absolute; display: block; left: -30px; bottom: 0; }
    .footer-left-cow-image img { width: 150px; }
    .footer-right-cow-image { position: absolute; display: block; right: -30px; bottom: 0; }
    .footer-right-cow-image img { width: 200px; }
}
@media(min-width:1500px){
    .footer .container { max-width: 1170px; }
    .footer-left-cow-image { left: 0px; }
    .footer-right-cow-image { right: 0px; }
    .footer-left-cow-image img,
    .footer-right-cow-image img { width: auto; }
}
/*******************************
Footer Style End
*******************************/

/*******************************
All Inner Page Banner Style Start
*******************************/
.banner{
    height: 250px;
    background-position: right center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color:#9D3102;
}
.banner::after{
    content: "";
    background: rgba(157,49,2, 0.7);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}
.banner .banner-content {
    width: 100%;
    text-align: center;
    margin: 0;
    z-index: 100;
    position: relative;
}
.breadcrumb{
    background: transparent; 
    justify-content: center;
    margin: 0;
    padding: 10px 0;
}
.breadcrumb li { font-size: 16px; }
.breadcrumb .breadcrumb-item a{color: #fff; }
.breadcrumb .breadcrumb-item.active{color: rgb(255 255 255 / 70%);}
.breadcrumb-item+.breadcrumb-item::before{
    content: ""; 
    background: url("../img/organisation/bridcumb_arrow_right.svg") no-repeat; 
    width: 34px; 
    height: 24px; 
    display: inline-block;
}

@media(min-width:992px){
    .banner::after{
        content: "";
        background: linear-gradient(90deg, rgba(157,49,2,1) 35%, rgba(157,49,2,0) 70%);
    }
    .breadcrumb { padding: .75rem 1rem; }
    .banner .banner-content{text-align: left;}
    .breadcrumb { justify-content: left; padding: 20px 0; }
}

/*******************************
All Inner Page Banner Style End
*******************************/

/*******************************
Certifications Page Style Start
*******************************/

.tab_for_organization_page {
    padding: 50px 0;
    min-height: 600px;
}
.tab_for_organization_page img{object-fit: cover; width: 100%;}
.tab_for_organization_page .tab_container {margin-top: 30px; position: relative;}
.tab_for_organization_page h2 { margin-bottom: 20px; color:#9D3102; text-align: center; }
.tab_for_organization_page h3 { font-size: 20px; line-height: normal; }
.tab_for_organization_page .btn-readmore{background-color: #EAAF00; color: #ffffff; margin-top: 20px;}
.tab_for_organization_page .btn-readmore:hover{background-color: #9D3102; color: #ffffff;}
.tab_for_organization_page .tabs {display: none; }
.tab_for_organization_page .tab_drawer_heading {
    background-color: #ffffff;
    border-top: 1px solid #707070;
    margin: 0;
    padding: 14px 20px;
    display: block;
    cursor: pointer;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.tab_for_organization_page .tab_container .tab_drawer_heading::after{
    content: ""; 
    background: url("../img/select-dropdown.svg") no-repeat right center;
    position: absolute;
    width: 10px;
    height: 10px;
    right: 20px;
    top: 24px;
}
.tab_for_organization_page .tab_container .d_active::after {transform: rotate(180deg);}
.tab_for_organization_page .d_active {
    background-color: #EAAF00;
    color: #fff;
}
.tab_for_organization_page .tab_content {
    padding: 20px;
    background-color: rgb(255 255 255 / 70%);
}
.tab_for_organization_page .tab_content p{
    font-size: 16px;
    line-height: 28px;
}
.tab_for_organization_page .tab_container h3{font-size: 20px;}
.tab_for_organization_page .card { margin-bottom: 20px; }
.tab_for_organization_page .card-img-top { padding: 20px; border-radius: 0; background-color: #9D3102;}
.tab_for_organization_page .card-title { color: #9D3102;}
.tab_for_organization_page .card-body { border: 1px solid #9D3102; text-align: center;}
.tab_for_organization_page .card-title::after{
    content: "";
    border-bottom: 1px solid #B7B7B7;
    width: 90%;
    display: inline-block;
}

@media(min-width: 768px){
    .tab_for_organization_page .card { min-height: 100%; }
}

/* LightBox Style */
.lightbox-target {
    position: fixed;
    top: -100%;
    width: 100%;
    background: rgba(0,0,0,.7);
    width: 100%;
    opacity: 0;
    -webkit-transition: opacity .5s ease-in-out;
    -moz-transition: opacity .5s ease-in-out;
    -o-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
    overflow: hidden;
}
.lightbox-target img {
    margin: auto;
    position: absolute;
    top: 0;
    left:0;
    right:0;
    bottom: 0;
    max-height: 0%;
    max-width: 0%;
    border: 3px solid white;
    box-shadow: 0px 0px 8px rgba(0,0,0,.3);
    box-sizing: border-box;
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out; 
}
a.lightbox-close {
    display: block;
    width:50px;
    height:50px;
    box-sizing: border-box;
    background: white;
    color: black;
    text-decoration: none;
    position: absolute;
    top: -80px;
    right: 0;
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
}
a.lightbox-close:before {
    content: "";
    display: block;
    height: 30px;
    width: 1px;
    background: black;
    position: absolute;
    left: 26px;
    top:10px;
    -webkit-transform:rotate(45deg);
    -moz-transform:rotate(45deg);
    -o-transform:rotate(45deg);
    transform:rotate(45deg);
}
a.lightbox-close:after {
    content: "";
    display: block;
    height: 30px;
    width: 1px;
    background: black;
    position: absolute;
    left: 26px;
    top:10px;
    -webkit-transform:rotate(-45deg);
    -moz-transform:rotate(-45deg);
    -o-transform:rotate(-45deg);
    transform:rotate(-45deg);
}
.lightbox-target:target {
    opacity: 1;
    top: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 10000;
}
.lightbox-target:target img {
    max-height: 100%;
    max-width: 400px;
}
.lightbox-target:target a.lightbox-close { top: 0; }
/* LightBox Style */

@media (min-width:768px){
.tab_for_organization_page{
    padding: 70px 0;
    position: relative;
}

.tab_for_organization_page h2 { margin-bottom: 50px; }
.tab_for_organization_page ul.tabs {
	padding: 0;
	list-style: none;
	width: 100%;
    margin-top: 20px;
    border-bottom: 1px solid #E2E2E2;
}
.tab_for_organization_page .tabs { display: inline-flex;  }
.tab_for_organization_page ul.tabs li {
	float: left;
	cursor: pointer;
	padding: 10px 30px;
	line-height: 31px;
	border-bottom: 2px solid transparent;
	background-color: transparent;
	overflow: hidden;
	position: relative;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
}
.tab_for_organization_page ul.tabs li:hover {
	background-color: transparent;
	color: #707070;
}
.tab_for_organization_page ul.tabs li.active {
	color: #9D3102;
    font-weight: 600;
	border-bottom: 2px solid #9D3102;
	display: block;
}
.tab_for_organization_page .tab_drawer_heading { display: none;}
.tab_for_organization_page .tab_content{padding: 0;}
}

/*******************************
Certifications Page Style End
*******************************/


/*******************************
Gallery Page Style Start
*******************************/
.gallery { padding: 40px 0;}
.gallery h2 { margin-bottom: 35px; color: #9D3102;}
.gallery_product { margin-bottom: 15px; }
.video-wrapper { height: 320px; }
.video-wrapper h3 { margin: 20px 0 0; font-size: 20px; font-weight: 700; }
@media (min-width:768px){
    .gallery { padding: 70px 0;}
    .gallery_product { margin-bottom: 30px; }
    .gallery h2 { margin-bottom: 50px; }
}
/*******************************
Gallery Page Style End
*******************************/

/*******************************
Donation Page Style Start
*******************************/
.donation { padding: 40px 0; }
.donation h2 { margin-bottom: 35px; color: #9D3102;}
@media (min-width:768px){
    .donation { padding: 70px 0; }
    .donation h2 { margin-bottom: 50px; }
}
/*******************************
Donation Page Style End
*******************************/
/*******************************
Donator View Page Style Start
*******************************/
.donation-testimonial { padding: 40px 0 25px; }
.donation-testimonial .testimonial-wrapper { margin-bottom: 30px; min-height: inherit; }
@media(min-width:768px){
    .donation-testimonial { padding: 70px 0 55px; }
}
/*******************************
Donator View Page Style End
*******************************/

/*******************************
Trustee Page Style Start
*******************************/
.trustee { padding: 70px 0 0; }
.trustee h2 { margin-bottom: 35px; color: #9D3102;}
.trustee .row.mb-5 { margin-left: -5px; margin-right: -5px; }
.trustee .row.mb-5 .col-6 { padding: 0 5px; margin-bottom: 10px; }
.trustee .card {height: 100%; padding: 10px !important; border: 1px solid #E2E2E2; border-radius: 0; }
.trustee .card-img-left { width: 90px; height: 90px; flex: 0 0 90px; background-size: cover; background-repeat: no-repeat; background-position: center center; }
.trustee .card-body { text-align: center; padding: 0; border: 0; }
.trustee .card-body h3 { font-size: 15px; color: #9D3102; line-height: 20px; text-align: center; }
.trustee .card-body p { font-size: 12px; }
.trustee .card-body p a { color: #707070; }
.trustee .card-body p a:hover { color: #9D3102; }
.text-muted { font-size: 14px; }
@media(min-width:768px){
    .trustee { padding-bottom: 30px; }
    .trustee h2 { margin-bottom: 50px; }
    .rounded-md-0 { border-radius: 0 !important; }
    .trustee .card { padding: 0 !important; }
    .trustee .card-body { padding: 0 1.25rem; text-align: left; }
    .trustee .card-body h3 { line-height: 22px; font-size: 16px; text-align: left; }
    .trustee .card-body p { font-size: 16px; }
    .trustee .row.mb-5 { margin-left: -15px; margin-right: -15px; }
    .trustee .row.mb-5 .col-6 { padding: 0 15px; margin-bottom: 1rem; }
    .trustee .card-img-left { width: 154px; height: 154px; flex: 0 0 154px; }
    .text-muted { font-size: 18px; }
}
@media(min-width:992px){
    .trustee .card-body h3 { line-height: 26px; font-size: 19px; }
}
/*******************************
Trustee Page Style End
*******************************/

/*******************************
Animal Served Page Style Start
*******************************/
.animal-served { padding: 40px 0; }
.animal-served h2 { margin-bottom: 35px; color: #9D3102; }
.animal-served-data table tr { background-color: rgba(234,175,0,.2); }
.animal-served-data table tr:nth-child(odd) { background-color: rgba(234,175,0,.1); }
.animal-served-data table tr th { padding: 10px 15px; font-size: 14px; line-height: 20px; font-weight: 500; color: #fff; text-align: left; background-color: #9D3102; }
.animal-served-data table tr th:first-child,
.animal-served-data table tr td:first-child { width: 30%; }
.animal-served-data table tr td { padding: 10px 15px; font-size: 12px; text-align: left; border: none;}
@media(min-width:768px){
    .animal-served { padding: 70px 0; }
    .animal-served h2 { margin-bottom: 50px; }
    .animal-served-data table tr th { padding: 15px 25px; font-size: 18px; line-height: 24px; }
    .animal-served-data table tr td { padding: 15px 25px; font-size: 18px; }
}
/*******************************
Animal Served Page Style End
*******************************/

/*******************************
Contact us Page Style Start
*******************************/
.contact-us { padding: 40px 0 25px; }
.contact-us .main-form { padding: 20px; background-color: #EAAF00; color: #fff; }
.contact-us .main-form .btn-send { width: 100%; background-color:#9D3102; color: #fff; border-radius: 0; }
.contact-us .main-form input.form-control {
    padding: 12px 15px;
    height: 52px;
    font-size: 15px;
    border: solid 1px #fff;
    border-radius: 0;
    background-clip: border-box;
}
.contact-us .main-form .form-group.error input:focus { box-shadow: none; }
.contact-us .main-form .form-group.error input.form-control { border: solid 1px #f00; }
.contact-us .main-form textarea.form-control {
    height: 130px;
    font-size: 15px;
    border: none;
    border-radius: 0;
    padding: 12px 15px;
}
.contact-us h3 {
    line-height: 30px;
    font-size: 20px;
    font-weight: 500;
    color: #9D3102;
}
.contact-us .location-info { max-width: 100%; margin-left: 0px;}
.contact-us .location-info h4{font-size: 18px; line-height: 22px; font-weight: 600; color: #9D3102; text-transform: uppercase;}
.contact-us .location-info svg{width: 30px; height: 30px;}
.contact-us .location-info a{color:#9D3102;}
@media(min-width:768px){
    .contact-us { padding: 70px 0; }
    .contact-us .location-info { width: 360px; max-width: 100%; }
    .contact-us .main-form { padding: 60px; }
}
/*******************************
Contact us Page Style End
*******************************/

/*******************************
Donation Plan Page Style Start
*******************************/
.donation .row { margin: 0 -5px; }
.donation .row .col-6,
.donation .row .col-lg-12 { padding: 0 5px; }
.donation .card { margin-top: 0px; border:1px solid #9D3102; border-radius: 0; height: 100%; }
.donation .card-img-top { padding: 0; max-height: 180px; object-fit: cover;}
.donation .card-body { padding: 10px; color: #9D3102; }
.donation .card-title { min-height: 42px; font-size: 14px; line-height: 20px; font-weight: 600; }
.donation .card-subtitle { font-size: 12px; padding-bottom: 10px;}
.donation .card-body .value { font-size: 16px; padding-top: 10px; font-weight: 600; border-top: 1px solid rgb(10, 10, 10, 0.2); }
.donation .card-body .value span { font-weight: 400;}
.donation .alert-warning { background-color: #FFDCBA; color: #9D3102; }
.donation .alert-warning li { font-size: 14px; }
@media(min-width:768px){
    .donation .row { margin: 0 -15px; }
    .donation .row .col-6,
    .donation .row .col-lg-12 { padding: 0 15px; }
    .donation .card { margin-top: 0px; }
    .donation .card-body { padding: 1.25rem; }
    .donation .card-title { min-height: inherit; font-size: 18px; line-height: 24px; }
    .donation .card-subtitle { font-size: 16px; padding-bottom: 20px; }
    .donation .card-body .value { font-size: 28px; padding-top: 20px; }
    .donation .alert-warning li { font-size: 18px; }
}
/*******************************
Donation Plan Page Style End
*******************************/

/*******************************
Collection Center Page Style Start
*******************************/
.donation .collection-center h3 { padding: 8px 15px; font-size: 18px; background-color:#9D3102; color: #fff; margin-bottom: 10px; }
.donation .collection-center .collection-center-info { padding: 10px 0 0; width: 100%;}
.donation .collection-center .collection-center-info h4 { font-size: 18px; font-weight: 500; color: #9D3102; }
.donation .collection-center .collection-center-info p { margin-bottom: 10px; }

@media(min-width:768px){
    .donation .collection-center h3 { padding: 8px 20px; font-size: 26px; margin-bottom: 20px; }
    .donation .collection-center .collection-center-info { width: 80%; padding: 20px 10px; }
    .donation .collection-center .collection-center-info p { margin-bottom: 1.5rem; }
    .donation .collection-center .collection-center-info h4 { font-size: 24px; }
}

/*******************************
Collection Center Page Style End
*******************************/

/*******************************
Sitemap Page Style Start
*******************************/
.sitemap{padding: 70px 0;}
.sitemap h2 {
    margin-bottom: 50px;
    color: #9D3102;
}
.sitemap ul li, ol li{display: block;}
.sitemap ul, ol{
    list-style: none;
    padding: 0;
    margin: 0;
}
.sitemap .list-bold > li {
    font-size:16px;
    font-weight: 700;
    color: #9D3102;
}
.sitemap .list-bold > li + li{margin-top: 15px;}
.sitemap .list-bold > li > a, .list-bold > li > a:active, .list-bold > li > a:focus {color: inherit;}
.sitemap .list-bold > li > a:hover{color: #EAAF00;}
.sitemap .marked-list > li {
    position: relative;
    padding-left: 25px;
    font-size:14px;
    font-weight: 400 ;
    line-height: 24px;
    color: #9D3102;
}
.sitemap .marked-list > li + li{margin-top: 10px;}
.sitemap .marked-list > li::before{
    content: "";
    position: absolute;
    border: 0 solid #9D3102;
    height: 8px;
    width: 8px;
    border-width: 0 1px 1px 0;
    transform: rotate(-45deg);
    left: 0;
    top: 7px;
}
.sitemap .marked-list > li a, .marked-list > li a:active, .marked-list > li a:focus {color: inherit;}
.sitemap * + .marked-list{margin-top: 12px;}
.sitemap .marked-list > li a:hover {color: #EAAF00;}

/*******************************
Sitemap Page Style End
*******************************/

.error-404{padding: 70px 0;}
.error-404 h2.error{
    font-size: 110px;
    color: #9D3102;
    font-weight: 400;
    line-height: 110px;
}
.error-404 h2.title{color: #000; font-weight: 400;}
.collection-center-info a { color: #000; }
